import React, { ChangeEvent, FocusEvent } from 'react';
import { CRow, CCol, CFormGroup, CLabel } from '@coreui/react';
import THEME from 'theme';
import { TextInput } from './TextInput';
import Typeahead from './Typeahead';

/**
 * Component to render row with text input in Card
 * @prop    label                  Label of the field
 * @prop    id                     Id to be passed to CInput.id
 * @prop    placeholder            Place holder for input
 * @prop    value                  Value variable
 * @prop    onChange               Event handler fired on value change
 * @prop    onBlur                 Event handler fired on input blur
 * @prop    invalid                Boolean to be used to determine whether to apply invalid styles or not
 * @prop    invalidText            Error text to be shown
 * @prop    type                   Type of input
 * @prop    disabled               If true, input would be disabled
 * @prop    showRupeeSymbol        If true, Rupee symbol is prepended to input using CInputGroup
 * @prop    showMobilePrefix       If true, +91 is prepended to input using CInputGroup
 * @prop    showInfoIcon           If true, Info icon is prepended to input
 * @prop    onPrefixClick          a function that handles what happens when the user clicks on the prefix icon
 * @prop    infoIconTooltipContent the content or info to be shown when the icon is clicked
 * @prop    name                   Name of input
 */
export const CardTextInputRow = <T extends TypeaheadOption>({
  label,
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  invalid,
  invalidText,
  type = 'text',
  disabled = false,
  showRupeeSymbol = false,
  showMobilePrefix = false,
  showInfoIcon = false,
  onInfoPrefixClick = undefined,
  infoIconTooltipContent = undefined,
  typeaheadOptions = [] as T[],
  onTypeaheadChange = undefined,
  onTypeaheadSelect = undefined,
  required = false,
}: {
  label: string;
  id: string;
  name?: string;
  placeholder: string;
  value?: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  invalid?: boolean;
  invalidText?: string;
  type?: InputTypes;
  disabled?: boolean;
  showRupeeSymbol?: boolean;
  showMobilePrefix?: boolean;
  showInfoIcon?: boolean;
  onInfoPrefixClick?: (() => void) | undefined;
  infoIconTooltipContent?: (() => JSX.Element) | undefined;
  typeaheadOptions?: T[];
  onTypeaheadChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onTypeaheadSelect?: ((item: T) => void) | undefined;
  required?: boolean;
}) => {
  return (
    <CRow>
      <CCol xs="12">
        <CFormGroup>
          <CLabel
            className="text-muted font-weight-bold"
            style={{ fontSize: THEME.SIZES.INPUT_LABEL_FONT_SIZE }}
            htmlFor={id}
          >
            {required && <span style={{ color: 'red' }}>*</span>} {label}
          </CLabel>
          {type === 'typeahead' ? (
            <Typeahead
              id={id}
              value={value}
              name={name}
              placeholder={placeholder}
              typeaheadOptions={typeaheadOptions}
              onTypeaheadChange={onTypeaheadChange}
              onTypeaheadSelect={onTypeaheadSelect}
            />
          ) : (
            <TextInput
              id={id}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              invalid={invalid}
              type={type}
              disabled={disabled}
              onBlur={onBlur}
              showRupeeSymbol={showRupeeSymbol}
              showMobilePrefix={showMobilePrefix}
              showInfoIcon={showInfoIcon}
              onInfoPrefixClick={onInfoPrefixClick}
              infoIconTooltipContent={infoIconTooltipContent}
              invalidText={invalidText}
              required={required}
            />
          )}
        </CFormGroup>
      </CCol>
    </CRow>
  );
};
