import { CInput, CListGroup, CListGroupItem } from '@coreui/react';

import React, { ChangeEvent, useEffect, useState } from 'react';

/**
 * Component to render row typeahead
 * @prop    id                     Id to be passed to CInput.id
 * @prop    name                   Name of input
 * @prop    placeholder            Place holder for input
 * @prop    T                      Generic type for the typeahead options
 * @prop    typeaheadOptions       List of options
 * @prop    onTypeaheadChange      Function to handle input change
 * @prop    onTypeaheadSelect      Function to handle selection of option
 */
const Typeahead = <T extends TypeaheadOption>({
  id,
  value,
  name,
  placeholder,
  typeaheadOptions,
  onTypeaheadChange,
  onTypeaheadSelect,
}: {
  id: string;
  value?: string | number;
  name?: string;
  placeholder: string;
  typeaheadOptions: T[];
  onTypeaheadChange: ((e: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onTypeaheadSelect: ((item: T) => void) | undefined;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      const selectedOption = typeaheadOptions.find((item) => item.id === Number(value));
      setInputValue(selectedOption?.name ?? '');
    }
  }, [value, typeaheadOptions]);

  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <CInput
        onBlur={() => setTimeout(() => setIsFocused(false), 100)}
        onFocus={() => setIsFocused(true)}
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (onTypeaheadChange) {
            onTypeaheadChange(e);
          }
          setInputValue(e.target.value);
        }}
      />
      {typeaheadOptions.length > 0 && isFocused && (
        <CListGroup
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 2,
            background: '#F2F2F2',
            height: 'auto',
            maxHeight: 200,
            overflowY: 'scroll',
            cursor: 'pointer',
          }}
        >
          {typeaheadOptions.map((item) => (
            <CListGroupItem
              key={item.id}
              value={item.id}
              onClick={() => {
                if (onTypeaheadSelect) {
                  setInputValue(item.name);
                  onTypeaheadSelect(item);
                }
              }}
            >
              {item.name}
            </CListGroupItem>
          ))}
        </CListGroup>
      )}
    </div>
  );
};

export default Typeahead;
